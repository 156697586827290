@use "#v2-components/atoms/colors" as c;
@use "#v2-components/molecules/breakpoint.module" as breakpoint;
@use "#v2-components/molecules/text.module" as text;

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
  button {
    display: flex;
    gap: 4px;
    flex-direction: column;
    @include text.body-2;
    width: 100%;
    align-items: center;
    align-content: center;
    div {
      width: 100%;
      background-color: c.$charcoal-300;
      height: 4px;
      border-radius: 2px;
    }
    .active {
      background-color: c.$charcoal-600;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .main {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .details {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      padding: 0 40px;
      margin: auto 0;
      text-align: center;

      @include breakpoint.display-to("s") {
        padding: 8px 16px 40px 16px;
      }

      h3 {
        @include text.header-3;
      }

      p {
        @include text.body-2;
        span {
          color: c.$success-green-medium;

          @include breakpoint.display-to("s") {
            display: block;
          }
        }
      }
    }

    .disclaimer {
      background: c.$charcoal-100;
      padding: 16px 40px 24px 40px;

      @include breakpoint.display-to("s") {
        padding: 16px;
      }
    }
  }

  .buttonsContainer {
    display: flex;
    gap: 16px;
    margin-top: auto;
    padding: 24px 16px;
    border-top: 1px solid c.$charcoal-200;
    box-shadow: 0px -2px 4px -2px rgba(56, 57, 58, 0.1),
      0px -4px 8px -1px rgba(56, 57, 58, 0.1);

    @include breakpoint.display-to("s") {
      flex-direction: column-reverse;
    }

    button {
      width: 100%;
    }
  }
}

// Autopay date
.autopayDateContainer {
  padding-top: 40px;
  height: calc(100% - 45px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include breakpoint.display-to("s") {
    padding-top: 24px;
  }

  p {
    @include text.body-1;
  }

  .main {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include breakpoint.display-to("s") {
      padding: 0 24px;
    }

    .details {
      display: flex;
      flex-direction: column;
      gap: 16px;

      h3 {
        @include text.header-3;
      }
    }
  }

  .buttonsContainer {
    margin-top: auto;
    padding: 24px 16px;
    border-top: 1px solid c.$charcoal-200;
    box-shadow: 0px -2px 4px -2px rgba(56, 57, 58, 0.1),
      0px -4px 8px -1px rgba(56, 57, 58, 0.1);

    button {
      width: 100%;
    }
  }
}

.reviewContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;
  height: calc(100% - 50px);

  h3 {
    @include text.header-3;
  }

  p {
    @include text.body-1;

    strong {
      font-weight: 600;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 40px;

    @include breakpoint.display-to("s") {
      padding: 0 16px;
    }
  }

  .box {
    border: 1px solid rgba(91, 97, 110, 0.2);
    display: flex;
    flex-direction: column;
    padding: 24px;
    box-shadow: 0px 6px 10px rgba(91, 97, 110, 0.04),
      0px 1px 18px rgba(91, 97, 110, 0.04), 0px 3px 5px rgba(91, 97, 110, 0.02);
    border-radius: 12px;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        border-bottom: 1px solid c.$charcoal-200;
        padding: 16px 0;
      }

      &:first-child {
        border-bottom: 1px solid c.$charcoal-200;
        padding-top: 0;
      }

      &:last-child {
        padding-top: 16px;
      }
    }

    .bankAccountDetails {
      max-width: 160px;
      text-align: right;
    }

    .pendingFunding {
      color: c.$charcoal-400;
    }

    .boxDisclaimer {
      padding-top: 16px;
      align-self: flex-end;
      font-size: 12px;
    }
  }

  .disclaimer {
    margin-top: auto;
    padding: 24px 16px;
    border-top: 1px solid c.$charcoal-200;
    box-shadow: 0px -2px 4px -2px rgba(56, 57, 58, 0.1),
      0px -4px 8px -1px rgba(56, 57, 58, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 16px;

    p {
      @include text.body-2;

      strong {
        font-weight: 600;
        display: block;
      }

      a {
        color: c.$blue-medium;
        text-decoration: underline;
      }
    }

    button {
      width: 100%;
    }
  }
}

.paymentMethodContainer {
  height: calc(100% - 45px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .main {
    padding: 40px;
  }

  .details {
    display: flex;
    flex-direction: column;
    gap: 40px;

    p {
      @include text.body-2;
    }

    .titleSection {
      display: flex;
      flex-direction: column;
      gap: 16px;
      p {
        @include text.body-1;
      }

      h3 {
        @include text.header-3;
      }
    }
  }

  .accountWidget {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 28px 12px;
    // border: 1px solid c.$charcoal-400;
    box-shadow: 0px 6px 10px rgba(91, 97, 110, 0.04),
      0px 1px 18px rgba(91, 97, 110, 0.04), 0px 3px 5px rgba(91, 97, 110, 0.02);
    border-radius: 12px;
    gap: 12px;

    @include breakpoint.display-to("s") {
      flex-direction: column;
      justify-content: center;
    }

    div {
      text-align: center;
    }

    p {
      @include text.body-2;
      color: c.$charcoal-400;
      display: inline;

      @include breakpoint.display-to("s") {
        display: block;
      }
    }
  }

  .buttonsContainer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    padding: 24px 16px;
    border-top: 1px solid c.$charcoal-200;
    box-shadow: 0px -2px 4px -2px rgba(56, 57, 58, 0.1),
      0px -4px 8px -1px rgba(56, 57, 58, 0.1);

    button {
      width: 100%;
    }
  }
}

.declineContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .main {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include breakpoint.display-to("s") {
      padding: 0 16px;
      padding-bottom: 40px;
    }

    h3 {
      @include text.header-3;
    }

    .details {
      background: c.$warning-brown-pale;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 24px 16px;

      h4 {
        display: flex;
        align-items: center;
        gap: 8px;
        @include text.body-1;
        font-weight: 600;
      }

      p {
        @include text.body-1;
      }

      hr {
        border: 1px solid c.$charcoal-600;
        width: 100%;
      }

      .comparisonContainer {
        display: flex;
        gap: 16px;

        @include breakpoint.display-to("s") {
          flex-direction: column;
        }

        & > div {
          width: 100%;
          border-radius: 4px;
          padding: 16px;

          p:not(:first-child) {
            font-size: 14px;
          }
        }

        .autopayCard {
          background: rgba(34, 34, 34, 0.05);

          strong {
            font-weight: 600;
          }

          p {
            color: c.$charcoal-400;
          }
        }

        .standardCard {
          background: c.$white-100;

          p:not(:nth-child(2)) {
            font-weight: 600;
          }
        }
      }
    }
  }

  .buttonsContainer {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    margin-top: auto;
    padding: 24px 16px;
    border-top: 1px solid c.$charcoal-200;
    box-shadow: 0px -2px 4px -2px rgba(56, 57, 58, 0.1),
      0px -4px 8px -1px rgba(56, 57, 58, 0.1);

    @include breakpoint.display-to("s") {
      flex-direction: column-reverse;
    }

    button {
      width: 100%;
    }
  }
}
