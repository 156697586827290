/********************************************************************************************************
* Borders
********************************************************************************************************/

/** ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Radius ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ **/
/* small radius */
$border-radius-small: 8px;

/* circle radius */
$border-radius-circle: 640px;
