// large tablet and small desktop are too close, could cause confusion and is not a meaningful difference.
// This should be cleaned up asap to prevent tech debt and strange breakpoint conditions.
// BREAKPOINTS
$screen-itty-bitty: 450px;
$screen-small-tablet: 620px;
$screen-medium-tablet: 820px;
$screen-large-tablet: 1024px;
$screen-small-desktop: 1080px;
$screen-large-desktop: 1440px;
$bp-desktop-xwide: 1920px;

// FONT SIZES
$fs-header-lg: 60px;
$fs-header: 40px;
$fs-header-sm: 28px;
$fs-body: 20px;
$fs-body-sm: 16px;
$fs-body-xs: 14px;
$fs-body-xxs: 12px;
$fs-body-tiny: 10px;

// GUTTERS
$gut-qt: 2px;
$gut-half: 4px;
$gut: 8px;
$gut-2: 16px;
$gut-3: 24px;
$gut-4: 32px;
$gut-8: 64px;

// BORDER RADIUS
$br-half: 2px;
$br: 4px;
$br-2: 8px;
$br-4: 16px;
$br-8: 32px;
$br-p50: 50%;

// COLORS
$clr-bg-1: #ffffff;
$clr-bg-2: #f2f2f2;
$clr-bg-3: #ebebeb;
$clr-bg-4: #d9d9d9;
$clr-bg-inv: #222222;
$clr-bg-inv-2: #545454;
$clr-bg-blue: #23a0c833; // This replaces #4faed233 for accessibility standards

$clr-txt-1: #222222;
$clr-txt-2: #878787;
$clr-txt-accent: #8e36ec;
$clr-txt-inv: white;
$clr-txt-inv-2: #bababa;
$clr-txt-blue: #23a0c8; // This replaces #4faed2 for accessibility standards

$clr-brand-1-light: #e8d2ff;
$clr-brand-accent-1: #dce0fc;
$clr-brand-accent-2: #dbf4fd;

$turo-purple: #593cfb;

$clr-blu-acc: #6fbad7;

// GRADIENTS
$gradient-brand: linear-gradient(
  95.93deg,
  $clr-brand-accent-2 -42.94%,
  $clr-brand-1-light 104.81%
);

// etc
$text-primary: #222222;
$text-secondary: #878787;
$text-inverse: white;

$bg-primary: white;
$background-secondary: #ebebeb;
$background-inverse: #222222;

$brand-primary: #9c44fb;
$brand-secondary: #dbf4fd;
