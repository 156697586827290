@use "#v2-components/atoms/typography" as t;
@use "#v2-components/atoms/colors" as c;
@use "#v2-components/atoms/borders" as b;
@use "#v2-components/molecules/text.module" as text;

// MPR, 2023/9/11: don't put much in here, we want to let the
// theme provide almost all of the styles here.
.buttonWrapper {
  // its extremely fine to override this, but we want
  // it so that in situations where all other things
  // are equal, buttons take priority
  z-index: 0;
  // this should be the default, but we want to be explicit
  cursor: pointer;

  // they all have this wrapper, and we always want the child
  // events to bubble
  span {
    pointer-events: none;
  }
}

.tenetButton {
  @include text.body-1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: b.$border-radius-circle;
  padding: 12px 24px;
  text-transform: uppercase;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    height: t.$base;
    width: t.$base;
  }
}

.FULL_WIDTH {
  width: 100%;
}
.LARGE {
  width: 296px;
}
.SMALL {
  @include text.body-2;
  padding: 8px 16px;
}
.ICON {
  padding: 0px;
  width: 32px;
  height: 32px;
  .leftArrow {
    display: none;
  }
  .rightArrow {
    display: none;
  }
  sub {
    display: none;
  }
  .chevronLeft {
    display: flex;
    margin-right: 2px;
  }
  .chevronRight {
    display: flex;
    margin-left: 2px;
  }
}

.DARK_FILLED {
  @extend .tenetButton;
  color: c.$white;
  background: c.$charcoal-600;
  &:hover {
    background: c.$charcoal-500;
  }
  &:active {
    background: c.$charcoal-600;
  }
  &:disabled {
    background: c.$charcoal-200;
    color: c.$charcoal-400;
  }
}

.DARK_BLUE_FILLED {
  @extend .tenetButton;
  color: c.$white;
  background: c.$blue-medium;
  &:hover {
    background: c.$blue-medium-hover;
  }
  &:active {
    background: c.$blue-electric;
  }
  &:disabled {
    background: c.$charcoal-200;
    color: c.$charcoal-400;
  }
}

.BLUE_FILLED {
  @extend .tenetButton;
  color: c.$white;
  background: c.$blue-electric;
  &:hover {
    background: c.$blue-medium-hover;
  }
  &:active {
    background: c.$blue-electric;
  }
  &:disabled {
    background: c.$charcoal-200;
    color: c.$charcoal-400;
  }
}

.PURPLE_FILLED {
  @extend .tenetButton;
  color: c.$white;
  background: c.$purple-medium;
  &:hover {
    background: c.$purple-medium-hover;
  }
  &:active {
    background: c.$purple-medium;
  }
  &:disabled {
    background: c.$charcoal-200;
    color: c.$charcoal-400;
  }
}

.DARK_OUTLINE {
  @extend .tenetButton;
  color: c.$charcoal-600;
  border: 1px solid c.$charcoal-600;
  &:hover {
    color: c.$charcoal-500;
    border: 1px solid c.$charcoal-500;
    background: c.$white-20;
  }
  &:active {
    background: c.$charcoal-100;
    border: 1px solid c.$charcoal-600;
  }
  &:disabled {
    color: c.$charcoal-300;
    border: 1px solid c.$charcoal-300;
  }
}

.BLUE_OUTLINE {
  @extend .tenetButton;
  color: c.$blue-medium;
  border: 1px solid c.$blue-medium;
  &:hover {
    color: c.$blue-medium-hover;
    border: 1px solid c.$blue-medium-hover;
    background: c.$white-20;
  }
  &:active {
    color: c.$blue-medium-hover;
    background: c.$blue-pale;
    border: 1px solid c.$blue-medium-hover;
  }
  &:disabled {
    color: c.$charcoal-300;
    border: 1px solid c.$charcoal-300;
  }
}

.PSEUDO_LINK {
  @extend .tenetButton;
  color: c.$blue-medium;
  padding: 0px;
  border-radius: 0px;
  text-transform: none;
  span {
    border-radius: 0px;
  }
  &:hover {
    color: c.$blue-medium-hover;
  }
  &:active {
    color: c.$blue-medium;
  }
  &:disabled {
    color: c.$charcoal-500;
  }
}

.PSEUDO_LINK_PURPLE {
  @extend .tenetButton;
  color: c.$purple-electric;
  padding: 0px;
  border-radius: 0px;
  text-transform: none;
  span {
    border-bottom: 1px solid c.$purple-electric;
    border-radius: 0px;
  }
  &:hover {
    color: c.$purple-electric-hover;
    span {
      border-bottom: 1px solid c.$purple-electric-hover;
    }
  }
  &:active {
    color: c.$purple-electric;
  }
  &:disabled {
    color: c.$charcoal-500;
    span {
      border-bottom: 1px solid c.$charcoal-500;
    }
  }
}

.PSEUDO_LINK_RED {
  @extend .tenetButton;
  color: #ad0000;
  padding: 0px;
  border-radius: 0px;
  text-transform: none;
  span {
    border-bottom: none;
    border-radius: 0px;
  }

  &:active {
    color: #ad0000;
  }
}

.PSEUDO_LINK_PINK {
  @extend .tenetButton;
  color: c.$pink-electric;
  padding: 0px;
  border-radius: 0px;
  text-transform: none;
  span {
    border-bottom: none;
    border-radius: 0px;
  }

  &:active {
    color: c.$pink-electric;
  }
}

.OUTLINED {
  @extend .tenetButton;
  color: c.$white;
  border: 1px solid white;
  &:hover {
    background: c.$charcoal-500;
  }
  &:active {
    background: c.$charcoal-600;
  }
  &:disabled {
    background: c.$charcoal-200;
    color: c.$charcoal-400;
  }
}

.WHITE_FILLED {
  @extend .tenetButton;
  color: c.$charcoal-600;
  background: white;
  &:hover {
    background: c.$charcoal-100;
  }
  &:active {
    background: c.$charcoal-200;
  }
  &:disabled {
    border: 1px solid c.$charcoal-300;
    color: c.$charcoal-300;
  }
}

.leftArrow {
  margin-right: 12px;
}
.chevronLeft {
  display: none;
}
.chevronRight {
  display: none;
}
.rightArrow {
  margin-left: 12px;
  &.Icon {
    display: none;
  }
}
