@use "#v2-components/atoms/colors" as c;
@use "#v2-components/molecules/breakpoint.module" as breakpoint;
@use "#v2-components/molecules/text.module" as text;

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  a {
    color: c.$blue-medium;
  }
  h1 {
    @include text.header-3;
  }
  ol {
    list-style-type: decimal;
    margin-left: 24px;
    @include text.body-1;
    li {
      padding: 6px 0;
    }
  }
  hr {
    width: 100%;
    color: c.$charcoal-200;
  }
  .footer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    @include text.body-2;
    & > div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    button {
      color: c.$blue-medium;
      margin-left: 4px;
      display: inline;
    }
    svg {
      height: 20px;
      margin-bottom: -4px; // I'm sorry, this was just the least code way to position the svg.
    }
  }
}
