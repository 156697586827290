/********************************************************************************************************
* Colors
********************************************************************************************************/

/* Neutral (light theme) */
$white: #ffffff;
$white-20: rgba(255, 255, 255, 0.2);
$charcoal-50: #878787;
$charcoal-100: #f9f9fb;
$charcoal-200: #e6e6e8;
$charcoal-300: #cbcbcb;
$charcoal-400: #8c8f92;
$charcoal-500: #38393a;
$charcoal-600: #222222;
$charcoal-600-transparent: #22222205;

$white-100: #ffffff;
$warning-pale: #fffae5;

/* Purple (light theme) */
$purple-extra-pale: #f8f1ff;
$purple-pale: #e8d2ff;
$purple-medium: #54099f;
$purple-medium-hover: #54099f99;
$purple-electric: #9c44fb;
$purple-electric-hover: #ae68f8; // FIXME need finalized color for this from design

/* Pink (light theme) */
$pink-pale: #fff0fb;
$pink-medium: #990979;
$pink-electric: #ca38aa;

/* Blue (light theme) */
$blue-pale: #e8f9ff;
$blue-pale-hover: #dcedf2;
$blue-partner: #003f73;
$blue-medium: #0c5a93;
$blue-medium-hover: #0a4876;
$blue-electric: #23a0c8;
$blue-trans: #23a0c833;

/* Success (light theme) */
$success-green-pale: #f0fdf4;
$success-green-medium: #036427;
$success-green-electric: #01ab59;

/* Warning (light theme) */
$warning-brown-pale: #fffae5;
$warning-brown-medium: #ca7900;
$warning-brown-electric: #3f1e00;

/* Red (light theme) */
$red-pale: #ffeae5;
$red-medium: #7f1d1d;
$red-medium-200: #ad0000;

/**************************** Portal v0 ***************************/
$primary-menu-gray-light: #f4eff9;
$primary-menu-gray-dark: #f9f9fb;
