@font-face {
  font-display: swap;
  font-family: "MatterSQ";
  font-weight: normal;
  src: url("./fonts/MatterSQ-Regular.woff2") format("woff2"),
    url("./fonts/MatterSQ-Regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "MatterSQMono";
  font-weight: normal;
  src: url("./fonts/MatterSQMono-Regular.woff2") format("woff2"),
    url("./fonts/MatterSQMono-Regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "MatterSQ";
  font-weight: bold;
  src: url("./fonts/MatterSQ-SemiBold.woff2") format("woff2"),
    url("./fonts/MatterSQ-SemiBold.woff") format("woff");
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  border: 0;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

article,
aside,
canvas,
figcaption,
figure,
footer,
header,
hgroup,
main,
output,
section {
  display: block;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  display: none;
}

body {
  -webkit-font-smoothing: subpixel-antialiased;
}

body,
#__next {
  background-color: white;
  background-size: 100%;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  line-height: 1;
}

#__next {
  background-color: white;
}

button,
input,
select,
textarea {
  appearance: none;
  background: none;
  border-color: currentColor;
  border-radius: 0;
  border-style: none;
  border-width: medium;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-stretch: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

button:disabled,
input:disabled,
select:disabled,
textarea:disabled {
  cursor: not-allowed;
}

html {
  display: flex;
  font-family: "MatterSQ", Helvetica, Arial, sans-serif;
  font-size: 62.5%;
  min-height: 100%;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

::-ms-clear,
::-ms-reveal {
  display: none;
}

::-webkit-contacts-auto-fill-button {
  display: none;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

::-webkit-search-cancel-button {
  appearance: none;
}

::-webkit-search-results-button {
  appearance: none;
}

::placeholder {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-stretch: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-weight: inherit;
  opacity: 1;
}

[data-input-type="mouse"] * {
  outline: none;
}

sup {
  vertical-align: super;
}

@keyframes CautionAnimation {
  0%{background-position:0% 0%}
  50%{background-position:50.5% 5.5%}
  100%{background-position:0% 0%}
}
body.ips {
  border: 3px transparent dashed;
  background: repeating-linear-gradient(  45deg,  darkred,  darkred 10px,  white 10px,  white 20px);
  animation: CautionAnimation 80s linear infinite;
  background-size: 200% 200% ;
}

body.ips #header-menu button:not(#user-profile-name)::after, body.ips .loan-app-header a::after {
  content: " Use Caution - Admin acting on behalf of customer";
  padding-left: 8px;
  font-size: 18px;
  color: darkred;
  font-weight: bold;
  text-decoration: none;
}
body.ips #header-menu button#user-profile-name, body.ips #header-menu button#user-profile-name * {
  color: darkred;
}
body.ips #header-menu button#user-profile-name::after {
  content: " (Via Admin)";
  padding-left: 4px;
}
body.ips button[type="submit"]::after {
  content: " (on behalf of customer)";
  padding-left: 4px;
}

@media (min-width: 800px)  {
  .live-chat-loader-placeholder>div {

      bottom: 25px !important;
      right: 85px !important;
      width: 48px !important;
      height: 48px !important;
      z-index: 99 !important;
  }
}

#loan-application-header {
  & + * {
    padding-top: 98px;
  }
  & ~ .isContitionallyHiddenMenuSibling {
    padding-top: inherit;
  }
  & ~ .isContitionallyHiddenMenuSibling + *:not(.isContitionallyHiddenMenuSibling) {
    padding-top: 98px;
  }
}

.globalPageWidth {
  margin: auto;

  @media (min-width: 1250px) {
    width: 100vw;
  }
}

#storybook-docs {
  b {
    font-weight: bold;
    color: #120066;
  }
}

.deferDisplay {
  content-visibility: auto;
}