@use "#styles/variables" as v;
@use "#v2-components/atoms/colors" as c;
@use "#v2-components/molecules/breakpoint.module" as breakpoint;
@use "#v2-components/molecules/text.module" as text;

.header {
  background-color: #ebebeb;
  width: 100%;

  & > div {
    margin-inline: auto;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    margin: 0 auto;

    @include breakpoint.display-from("s") {
      padding-inline: 64px;
      padding-block: 24px;
      width: 1110px;
    }
  }

  @media screen and (max-width: v.$screen-large-desktop) {
    width: 100%;
  }
  @media screen and (max-width: v.$screen-small-tablet) {
    padding-inline: 20px;
    padding-block: 12px;
  }
}
