@use "#styles/variables" as v;

.addPaymentsElement {
  display: flex;
  width: fit-content;
  font-size: v.$fs-body-sm;
  align-items: center;
  justify-content: center;

  > span {
    text-decoration: underline;
    margin-left: v.$gut;
  }
}
