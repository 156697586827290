@use "#v2-components/atoms/colors" as c;
@use "#v2-components/molecules/text.module" as text;
@use "#v2-components/molecules/breakpoint.module" as breakpoint;

.linkBox {
  @include text.disclaimer();
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px;
  padding: 12px;
  border: 1px dashed c.$charcoal-300;
  border-radius: 12px;
  width: 100%;

  @include breakpoint.display-from("s") {
    max-width: 350px;
  }

  svg {
    cursor: pointer;
  }
}
