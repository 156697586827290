@use "#v2-components/atoms/colors" as c;
@use "#v2-components/molecules/breakpoint.module" as breakpoint;
@use "#v2-components/molecules/text.module" as text;

.loadingContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  place-content: center;
  width: 100%;
  justify-content: center;
  align-items: center;

  p {
    @include text.body-1;
  }

  &.fullHeight {
    min-height: 90vh;
  }
}

.modalLoadingContainer {
  @extend .loadingContainer;
  &.fullHeight {
    min-height: 20vh;
  }
}

@keyframes blink {
  50% {
    color: transparent;
  }
}
.loaderDot {
  animation: 1s blink infinite;
}
.loaderDot:nth-child(2) {
  animation-delay: 250ms;
}
.loaderDot:nth-child(3) {
  animation-delay: 500ms;
}
