@use "#v2-components/molecules/breakpoint.module" as breakpoint;

@mixin inverse-modal-sticky-submit() {
  @include sticky-submit;
  margin-left: -40px;
  margin-right: -40px;
  @include breakpoint.display-to("s") {
    margin-left: -16px;
    margin-right: -16px;
  }
}

@mixin sticky-submit() {
  position: absolute;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  bottom: 0;
  width: 100%;
  background: white;
  padding: 24px 40px 24px 40px;
  box-shadow: 0px -4px 8px -1px rgba(56, 57, 58, 0.1),
    0px -2px 4px -2px rgba(56, 57, 58, 0.1);
  @include breakpoint.display-to("s") {
    padding: 16px 24px;
  }
}
