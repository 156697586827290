@use "#v2-components/molecules/text.module" as text;
@use "#v2-components/molecules/breakpoint.module" as breakpoint;

.modalWrapper {
  @include breakpoint.display-from("s") {
    max-width: 624px;
  }

  @include breakpoint.display-to("s") {
    max-height: 561px;
    align-self: end;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
}

.modalContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 24px;

  & > * {
    @include breakpoint.display-from("s") {
      max-width: 50%;
    }
  }

  & > img {
    width: 100%;
    padding-bottom: 8px; // auto height adjustment

    @include breakpoint.display-to("s") {
      max-width: 300px;
    }
  }

  & > h3 {
    @include text.header-3();

    @include breakpoint.display-to("s") {
      align-self: baseline;
    }
  }

  & > p {
    @include text.body-1();

    @include breakpoint.display-to("s") {
      align-self: baseline;
    }
  }
}

.modalButtonWrapper {
  align-self: end;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

  @include breakpoint.display-from("s") {
    align-self: center;
  }

  button {
    width: 100%;
  }
}
