@use "#v2-components/atoms/colors" as c;
@use "#v2-components/molecules/breakpoint.module" as breakpoint;
@use "#v2-components/molecules/text.module" as text;
@use "#v2-components/molecules/modal.module" as modal;

.container {
  position: fixed;
  z-index: 100;
  background-color: rgba(34, 34, 34, 0.64);
  height: 100%;
  width: 100%;
  @include breakpoint.display-from("s") {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(8px);
  }
}

.modal {
  position: relative;
  width: 80%;
  height: 80%;
  min-height: 600px;
  min-width: 320px;
  max-width: 1200px;
  max-height: 900px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @include breakpoint.display-to("s") {
    width: 100%;
    height: 100%;
    border-radius: 0;
    min-height: inherit;
  }
}

.title {
  display: flex;
  flex: 0 1 64px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  @include text.body-2();
  color: c.$charcoal-500;
  button {
    margin-left: auto;
  }
}

.span {
  display: flex;
  align-items: center;
  gap: 24px;
  span {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

.childElement {
  width: 100%;
  flex: 1 1 auto;
  overflow-y: scroll;
  @include breakpoint.display-to("s") {
  }
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.childElementWithPadding {
  @extend .childElement;
  padding: 40px;
  @include breakpoint.display-to("s") {
    padding: 24px 16px;
  }

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.stickyButton {
  @include modal.inverse-modal-sticky-submit;

  button {
    width: 100%;
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 12px 24px;
  }
}
html *:has(> .stickyButton) {
  /* This number needs to be relatively large to guarentee visibility + a little padding. 60 is not sufficent */
  padding-bottom: 90px;
}
